#nav{
	position: absolute;
	top:0;
	left:0;
	right:0;
	padding: 20px;
	z-index: 500;
	font-family: @title;

	.inner{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.menu{
		display: none;
	}

	#grip{
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 50px;
		width: 50px;
		background: @primary-bg;
		text-align: center;
		z-index: 5000;
		padding: 3px 8px 8px;
		cursor: pointer;
		border-radius: 0;


		.line {
			background: @text-alt;
			height: 2px;
			width: 35px;
			display: block;
			margin: 8px 0 0;
		}

		&:hover, &:focus-visible{
			background: color-mix(in srgb, #fff 10%, @primary-bg);
		}

		// svg{
		// 	margin: 12px auto 0;
		// 	width: 25px;
		// 	height: 25px;
		// 	color: @button-text;
		// }
	}

	#logo{
		position: relative;
		display: inline-block;
		height: 60px;
		line-height: 60px;

		img{
			display: block;
			height: 60px;
		}
	}

.tablet({

	.inner{
		position: relative;
		max-width: 95%;
	}

	.menu{
		width: 100%;
		display: block;

		ul{
			float: right;
			background: @secondary-bg;
		}

		li{
			float: left;
			height: 50px;
		}

		a{
			display: block;
			padding: 0 20px;
			background: @secondary-bg;
			color: @text-alt;
			line-height: 50px;
			height: 50px;
			font-weight: bold;
			font-size: 1.8rem;
			letter-spacing: 2px;
			&:hover{
				background: @primary-bg;
			}
		}
	}

	#logo{
		height: 120px;

		img{
			height: 120px;
			width: auto;
			max-width: none;
		}
	}

});


.desktop({

});
}