.block.square-gallery{
	position: relative;
	padding: 50px 20px;
	color: @text-alt;
	box-sizing: border-box;
	*, *:before, *:after{
		box-sizing: border-box;
	}

	.inner{
		display: flex;
		flex-wrap: wrap;
		gap: 5px;
	}

	.item{
		width: 100%;
		overflow: hidden;
		position: relative;
		a {
			cursor: zoom-in;
		}
		&::after {
			position: absolute;
			content: "";
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: #000;
			opacity: 0;
			pointer-events: none;
			transition: opacity 0.3s ease-in-out;
		}
		img {
			transform: scale(1.01);
			height: 100%;
			width: 100%;
			object-fit: cover;
			aspect-ratio: 1/1;
		}
		&:hover, &:focus-within {
			&::after {
				opacity: 0.4;
			}
		}
	}

	.vp(500px, {
		.item{
			width: calc(50% - 2.5px);
		}
	});

.tablet({
	background: none;
	.inner{
		justify-content: left;
	}

	.item{
		width: calc(25% - 3.75px);
	}
});


& + .block.explore {
	margin-top: 256px;
	position: relative;
		&::before {
			content: '';
			transition: .8s ease;
			height: 100px;
			background: linear-gradient(to bottom, transparent,  @secondary-bg);
			position: absolute;
			top: -100px;
			left: 0;
			width: 100%;
			opacity: 1;
			z-index: 1;
		}
}
}


.slbWrap {
	cursor: zoom-out;
}

.slbContentOuter {
	cursor: default;
	.slbImage {
	  padding: 3em 0 5em !important;
	}
	button {
	  box-shadow: none !important;
	  font-weight: 300 !important;
	  font-family: @heading !important;
	  font-size: 2em;
	  background-color: transparent !important;
		opacity: 0.8;
		&::before {
			display: none;
		}
		&:hover, &:focus {
			opacity: 1;
		}
	}
	.slbArrow {
		width: 2em;
	}
	.slbArrow::after {
		border: 0.5em solid transparent;
	}
	.slbCloseBtn {
	  right: 15px !important;
	  height: unset !important;
	  width: unset !important;
	  cursor: zoom-out;
	  color: #fff;
	}
	.slbCaption {
	//   height: 4em;
	  font-size: 16px;
	  bottom: 3em !important;
	  overflow: hidden;
	  display: flex;
	  justify-content: space-between;
	}

	.slbCaption-text {
	  overflow: hidden;
	  text-overflow: ellipsis;
	  text-align: left;
	  * {
		font-size: 16px;
	  }
	}
	.slbCaption-counter {
	//   height: 4em;
	  font-size: 16px;
	}
  }


