.block.generic-text{
	position: relative;
	background: @page-bg;
	padding: 50px 20px 100px;
	text-align: left;

	h1,h2,h3,h4,h5,h6{
		text-align: left;
	}

	h1{
		font-size: 4rem;
	}

	h2{
		font-size: 3.5rem;
		margin-bottom: 30px;
	}

	h3,h4,h5,h6{
		font-size: 2.5rem;
		margin: 15px 0;
	}

	h2, h3, h4, h5 {
		font-weight: 700;
		letter-spacing: 2px;
	}

	p, a, ul, ol{
		font-size: 1.6rem;
	}

	p, ul, ol {
		margin-bottom: 10px;
	}

	ul, ol{
		margin-left: 20px;
		list-style: none;
	}

	a {
		color: #3273dc;
		&:hover, &:focus{
			color: @text;
		}
	}

.tablet({
	padding: 50px 20px 150px;
});


.desktop({

});
}

