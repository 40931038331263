.block.simple-section{
	position: relative;
	padding: 50px 20px;
	text-align: center;

	h2, h3, h4,h5,h6{
		color: @heading;
		border-top: 1px solid @primary-bg;
		border-bottom: 1px solid @primary-bg;
		font-size: 3.6rem;
		font-weight: bold;
		margin-bottom: 30px;
		padding: 15px 0;
		letter-spacing: 1px;
	}

	p, ul, ol, dl{
		padding-bottom: 3rem;
	}

	a{
		.button;
	}

	&.dark{
		background: @secondary-bg;
		color: @text-alt;
		h2, h3, h4,h5,h6{
			color: @heading-alt;
		}
	}

	&.highlight{
		background: @primary-bg;
		color: @text-alt;
		h2,h3,h4,h5,h6{
			color: @text-alt;
			border-top: 1px solid @secondary-bg;
			border-bottom: 1px solid @secondary-bg;
		}
	}

	article{
		max-width: 700px;
		margin: auto;
	}

.tablet({
	padding: 90px 0 100px;
});


.desktop({

});
}

