#footer{
	position: relative;
	padding: 50px 20px;
	background: @secondary-bg;
	color: @text-alt;
	font-size: 1.8rem;
	&:before{
		position: absolute;
		top:-25px;
		left:0;
		right:0;
		height: 25px;
		content:"";
		background: url(/assets/img/black-torn.png) bottom center no-repeat;
		background-size: cover;
	}

	.inner img{
		max-width: 230px;
	}

	.scroll-top img {
		object-fit: contain;
    height: 125px;
    margin: 0 auto;
    display: flex
	}

	h6{
		color: @heading-alt;
		font-size: 3.2rem;
		padding: 0 0 10px;
		margin:0;
		font-family: @title;
		&.office-hours {
			font-size: 2.4rem;
			margin-top: 10px;
		}
	}

	h5{
		img{
			display: block;
			margin: auto;
		}
	}

	li{
		list-style: none;
	}

	a{
		color: @text-alt;

		&:hover, &:focus{
			color: @text-alt;
			text-decoration: underline;
		}
	}

	.box{
		padding-bottom: 50px;
	}

	.ada-terms {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
	}

	.equal-housing-icons {
		list-style: none;
		display: flex;
		display: inline-block;
		li {
			display: inline-block;
			svg {
				height: 20px;
				width: 20px;
			}
		}
	}

.tablet({
	// border-top: 92px solid #fff;
	padding: 48px 20px 80px;
	// margin-top: -150px;
	&:before{
		position: absolute;
		top:-89px;
		left:0;
		right:0;
		height: 90px;
		content:"";
		background: url(/assets/img/black-torn.png) bottom center repeat;
		// background-size: cover;
	}
	.inner{
		position: relative;
		display: flex;
		flex-wrap: wrap;
	}

	.box{
		width: 33.3%;
		padding-bottom:75px;
	}

	h5 img{
		margin-left: 0;
	}

	.copyright{
		width: 100%;
		border-top: 1px solid @primary-bg;
		padding-top: 15px;
		font-size: 1.7rem;
		display: flex;
		justify-content: space-between;
	}
});
}