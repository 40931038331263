.block.welcome{
	position: relative;
	padding: 50px 20px;

	h2{
		font-size: 5.2rem;
		text-transform: uppercase;
		color: @heading;
	}

	img{
		height: 140px;
		width: 140px;
		margin: auto;
	}

.tablet({
	padding-bottom:0 !important;
	// margin-bottom: -42px;
	margin-bottom: 5px;
	padding-top: 100px !important;
	.inner{
		overflow: hidden;
	}

	header{
		float: left;
		width: 30%;
		padding-bottom: 220px;
	}

	article{
		float: right;
		width: 65%;
		text-align: left;
	}

	figure{
		position: absolute;
		bottom: 0;
		left:0;
		width: 30%;
		margin: 0;
	}
});


.desktop({

});
}

.header + .welcome{
	padding: 0px 20px 50px;
}