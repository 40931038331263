.block.plain-gallery{
	position: relative;
	padding: 50px 20px;
	text-align: center;

	article{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 24px;
	}

	.item{
		width: 100%;
		height: 400px;
		box-sizing: border-box;
	}

	img {
		height: 100%;
    width: 100%;
    object-fit: cover;
	}

.tablet({

	article {
		&::before, &::after {
			content: '';
			position: absolute;
			width: 80px;
			height: 25px;
			border-top: 2px solid @primary-bg;
			border-bottom: 2px solid @primary-bg;
			top: 125px;
		}
		&::before {
			right: -80px;
		}

		&::after {
			left: -80px;
		}
	}
	.item{
		width: calc(33.33% - 16px);
		height: 550px;
	}
});


.desktop({

});
}

