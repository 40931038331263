html {
	font-size: 62.5%;
	scroll-behavior: smooth;
}

body{
	font-size: 2.2rem;
	line-height: 1.6;
	color: @text;
	font-family: @main;
	font-weight: 500;
}

img{
	display: block;
}

#mainwrap{
	overflow: hidden;
}

.inner{
	position: relative;
	margin: auto;
	max-width: @maxwidth;
	width: 100%;

	> header{
		padding-bottom: 35px;
	}
}

#cookie-box {
	z-index: 999999 !important;
	#cookie-button {
		background-color: @button-bg;
		color: @button-text;
		cursor: pointer;
		&::after, &::before {
			display: none;
		}
	}
}

.animated-element {
	opacity: 0;
}

#skip-link {
    position: fixed;
    top: 10px;
    right: 10px;
    transform: translateY(-200%);
    transition: transform 0.3s;
    z-index: 10000;
	padding: 12px;
	border-radius: 5px;
	background-color: @button-bg;
	color: @button-text;
	a {
		color: @button-text;
		text-decoration: underline;
		text-transform: uppercase;
		padding: 0 10px;
	}
  }

  #skip-link:focus-within {
    transform: translateY(0%);
  }


.tablet({

});