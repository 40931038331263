.block.image-box{
	position: relative;
	// padding: 50px 0;

	h2, h3, h4,h5,h6{
		margin: auto;
		font-size: 3.2rem;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 30px;
		padding: 10px 0;
		max-width: 350px;
	}

	figure{
		margin:0;
	}

	article{
		position: relative;
		padding: 100px 30px;
		color: @text-alt;
		text-align: center;
		box-sizing: border-box;
	}

	p, ul, ol, dl{
		padding-bottom: 1em;
		list-style: none;
	}

	a{
		.button;
	}

	&.dark {
		article{
			background: @secondary-bg;
		}
		h2, h3, h4,h5,h6{
			color: @text-alt;
			border-top: 1px solid @primary-bg;
			border-bottom: 1px solid @primary-bg;
		}
	}

	&.light {
		article{
			background: @primary-bg;
		}
		h2, h3, h4,h5,h6{
			color: @text-alt;
			border-top: 1px solid @text-alt;
			border-bottom: 1px solid @text-alt;
		}
	}

	&.dark + .block.image-box{
		padding-top:0;
		margin-top: -50px;
		article{
			background: @primary-bg;
		}
		h2, h3, h4,h5,h6{
			color: @text-alt;
			border-top: 1px solid @text-alt;
			border-bottom: 1px solid @text-alt;
		}
	}

	&.light + .block.image-box{
		padding-top:0;
		margin-top: -50px;
		article{
			background: @secondary-bg;
		}
		h2, h3, h4,h5,h6{
			color: @text-alt;
			border-top: 1px solid @primary-bg;
			border-bottom: 1px solid @primary-bg;
		}
	}


.tablet({
	padding: 0;
	background: @secondary-bg;
	overflow: hidden;
	font-size: 1.9rem;

	article{
		padding: 100px 90px 200px 90px;
		text-align: left !important;
	}

	h2,h3,h4,h5,h6{
		margin-left:0;
		text-align: left;
	}

	.inner{
		max-width: 100%;
	}

	article{
		margin-left: 50%;
		padding: 200px 40px 300px;
		> div {
			max-width: 700px;
			margin: 0 auto;
		}
	}

	figure{
		position: absolute;
		right: 50%;
		top:0;
		left: 0;
		bottom:0;
		overflow: hidden;

		img{
			float: right;
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}

	&.dark + .block.image-box{
		background: @primary-bg;
	}

	&.light + .block.image-box {
		background: @secondary-bg;

	}
	+ .block.image-box{
		figure{
			position: absolute;
			left: 50%;
			top:0;
			right: 0;
			bottom:0;
			overflow: hidden;

			img{
				float: left;
			}
		}

		article{
			margin-left:0;
			margin-right: 50%;
		}
	}
});


.desktop({

});
}

