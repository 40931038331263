.block.wide-image{
	position: relative;
	background: @secondary-bg;

	.inner{
		max-width: 100%;
	}

	&:after{
		// position: absolute;
		// top:0;
		// left:0;
		// bottom:0;
		// right:0;
		// content:"";
		// background: url(/assets/img/black-torn.png) bottom center no-repeat;
	}

	img{
		width: 100%;
	}

.tablet({

});


.desktop({

});
}

