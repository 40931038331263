.block.contact{
	position: relative;
	background: @page-bg;
	padding: 50px 20px 150px;
	text-align: left;

	h1,h2,h3,h4,h5,h6{
		text-align: left;
	}

	h1{
		font-size: 4rem;
	}

	h2,h3,h4,h5,h6{
		font-size: 3.2rem;
		padding: 15px 0 0 0;
	}

	h2 {
		font-size: 3.5rem;
		margin-bottom: 20px;
		font-weight: 700;
    letter-spacing: 2px;
	}

	a {
		color: @text-alt;
		&:hover, &:focus{
			text-decoration: underline;
		}
	}

	p, a {
		font-size: 1.6rem;
	}

	.field-wrap{
		padding-bottom: 12px;
	}


	.widget{
		position: relative;
		padding: 40px 20px;
		background: @secondary-bg;
		color: @text-alt;
		margin-bottom: 50px;

		h2, h3, h4,h5,h6{
			color: #BCBEC0;
			border-top: 1px solid @primary-bg;
			border-bottom: 1px solid @primary-bg;
			font-size: 2.8rem;
			font-weight: bold;
			text-transform: uppercase;
			margin-bottom: 30px;
			padding: 10px 0;
		}

	}

	form {
		.form-group {
			padding-bottom: 4px;
		}

		label {
			font-size: 1.6rem;
			font-weight: 400;
		}
		input, select, textarea {
			background-color: #EFEFEF;
			border: 0;
			padding: 6px 12px;
			font-size: 1.36rem;
			color: @text;
			text-indent: 0;
		}

		input, select {
			height: 3.7rem;
		 }

		 button {
			margin: 50px 0 40px;
		 }
	}

.tablet({
	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	article{
		width: 65%;
	}

	aside{
		width: 30%;
		font-size: 1.8rem;

		h2{
			max-width: 65%;
			font-size: 2.4rem !important;
		}
	}

	form{
		padding-top: 20px;
	}
});


.desktop({

});
}

