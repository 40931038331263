@media screen and (min-width: 768px){
	.mobile{
		display: none;
	}

	.desktop{
		display: block;
	}
}

.block.floorplan{
	padding: 45px 20px 160px;


	.inner{
		display: flex;
		flex-direction: column;
	}

	aside{
		position: relative;
		order: 2;
	}

	.widget{
		background-color: @secondary-bg;
		color: @text-alt;
		padding: 12px;
		margin-bottom: 10px;
		line-height: 1.2;
		a {
			color: @text-alt;
			font-size: 1.3rem;
			line-height: 1.5;
			&:hover, &:focus {
				text-decoration: underline;
			}
		}
		p {
			color: @text-alt;
			font-size: 1.3rem;
		}
		ul{
			list-style: none;
			display: flex;
		}

		li{
			max-width: 50%;
		}

		li + li{
			padding-left: 10px;
		}

		label{
			font-weight: bold;
		}

		h3{
			color: @text-alt;
			font-size: 1.6rem;
			font-family: @title;
			text-align: left;
			margin-bottom: 20px;
		}

		button, .button, input[type="submit"]{
			background: @button-bg;
			color: @button-text;
			padding: 8px;
			font-size: 1.6rem;
			cursor: pointer;
			&::before, &::after{
				display: none;
			}
		}
	}

	article{
		order: 1;
		position: relative;
		background: #F1F1F1;
		padding: 10px;
		border: 1px solid #DADADA;
		margin-bottom: 10px;
		list-style: none;

		h1 {
			text-align: left;
			font-size: 1.8rem;
			background: @secondary-bg;
			color: @text-alt;
			padding: 8px;
		}

		a{
			display: block;
			color: #333;
		}

		.thumbnail{
			display: block;
			padding-bottom: 30px;

			img{
				max-width: 99%;
				display: block;
				max-height: 100vh;
			}
		}

		.info{
			padding-bottom: 30px;
			* {
				font-size: 1.36rem;
			}
		}

		dl{
			overflow: hidden;
			padding: 20px 15px 10px;
			margin: 0;
		}

		dt{
			text-transform: uppercase;
			float: left;
			width: 30%;
			clear: both;
			font-size: 1.36rem;
		}

		dd{
			float: left;
			font-size: 1.36rem;
		}

		p{
			clear: both;
			font-style: italic;
			font-size: 1.6rem;
		}
	}

	#unit-table {
		display: none;
	}

	table{
		width: 100%;
		text-align: left;
		padding: 12px;
		// border-bottom: 1px solid #ddd;
		thead {
			position: relative;
		}
		tr{
			th{
				font-weight: bold;
				width: 50%;
				margin:0;
				color: @text-alt;
				font-size: 1.4rem;
			}

			td {
				font-size: 1.3rem;
			}

			td:first-of-type{
				padding: 5px;
				margin:0;
				// border: 1px solid #ddd;
				border-left: 0;
				border-bottom:0;
			}
		}

		tr:nth-child(odd){
			margin:0;
			// background: #F1F1F1;
		}
	}

	.fp-box {
		padding: 8px;
		background-color: @secondary-bg;
		* {
			color: @text-alt;
		}
		p {
			font-size: 1.3rem;
		}
		.empty {
			display: none;
		}
	}

	label{
		font-weight: normal !important;
		font-size: 1.3rem !important;
	}
	input, textarea, select{
		font-size: 1.3rem !important;
	}
	input {
		height: 3rem;
	}

.tablet({
	.inner{
		flex-direction: row;
		width: 100%;
		justify-content: space-between;
	}

	aside{
		position: static;
		width: 250px;
		margin-left: 20px;

		form{
			padding-top: 10px;
			.form-group {
				padding-bottom: 4px;
			}
			button {
				margin: 25px 0 20px;
			}
		}

		// label{
		// 	font-size: 1.6rem !important;
		// }

		// input, textarea, phone{
		// 	font-size: 1.7rem !important;
		// }
	}

	#unit-table {
		display: block;
		background-color: transparent;
		h3 {
			color: @text;
			text-align: left;
			margin-bottom: 20px;
			font-style: italic;
			font-weight: 700;
		}
		table, p {
			color: @text-alt;
			background-color: @secondary-bg;
		}

		p {
			padding: 0 12px 8px;
			&:last-of-type {
				padding-bottom: 12px;
			}
		}
	}

	article{
		width: ~"calc(100% - 270px)";
		padding:0;
		background: none;
		border:0;

		.active-image{
			background-color: @secondary-bg;
			img {
				margin: 0 auto;
				max-width: 99%;
			}
		}

		dl{
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: repeat(2, 1fr);
			grid-column-gap: 0px;
			grid-row-gap: 0px;
		}

		dt{
			border-bottom: 1px solid #ddd;
			clear: none;
			float: none;
			text-align: left;
			width: 100%;
		}

		dd{
			padding-left:0;
			margin-left:0;
		}

		.g1 { grid-area: 1 / 1 / 2 / 2; }
		.g2 { grid-area: 2 / 1 / 3 / 2; }
		.g3 { grid-area: 1 / 2 / 2 / 3; }
		.g4 { grid-area: 2 / 2 / 3 / 3; }
		.g5 { grid-area: 1 / 3 / 2 / 4; }
		.g6 { grid-area: 2 / 3 / 3 / 4; }
		.g7 { grid-area: 1 / 4 / 2 / 5; }
		.g8 { grid-area: 2 / 4 / 3 / 5; }
	}

	.widget{
		margin-bottom: 15px;
		.droplist{
			display: block;
			li{
				max-width: 100%;
				display: flex;
			}

			label{
				width: 45%;
				margin-right: 5%;
			}

			select{
				width: 50%;
			}

			li + li{
				padding: 10px 0 0 0;
			}

		}
	}
});


.desktop({

});
}

html.manual{
	.fp-box{
		display: none !important;
	}
}