.generic-supernav-overlay{
	// display: block !important;
	position: absolute;
	top:0;
	left:0;
	right: 0;
	z-index: 4000;

	.inner{
		position: relative;
		background: #fff;
		min-height: 100vh;
		box-sizing: border-box;
		display: block;
	}

	.title * {
		text-align: left;
		font-size: 3.0rem;
		margin: 0 0 20px;
		font-weight: bold;
		letter-spacing: 2px;
	}

	#close{
		position: absolute;
    box-sizing: border-box;
    top: 25px;
    right: 20px;
    height: 50px;
    width: 50px;
    background: @primary-bg;
    text-align: center;
    border-radius: 0px;
    z-index: 5000;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;


		.line {
			background: @text-alt;
			height: 2px;
			width: 35px;
			position: absolute;
			transform-origin: center;
			top: 50%;
			left: 50%;


			&:first-of-type{
				transform: translate(-50%, -50%) rotate(45deg);
			}
			&:last-of-type{
				transform: translate(-50%, -50%) rotate(-45deg);
			}
		}

		&:hover, &:focus-visible{
			background: color-mix(in srgb, #fff 10%, @primary-bg);
		}
	}

	.primary{
		position: relative;
		padding: 50px 20px 0 50px;
	}

	.secondary{
		position: relative;
		padding: 50px 20px 0 50px;
	}

	a{
		color: @text-link;
		.fontSize(2.0, 2.5, 2.5);
		letter-spacing: 2px;
		&:hover, &:focus{
			color: @text-link-hover;
		}
	}

	.mobile-only {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		gap: 5px;
		a {
			.button;
		}
	}

	address a, .phone-link a{
		color: @text;
		font-size: 1.6rem;
		&:hover, &:focus{
			color: @text;
			text-decoration: underline;
		}
	}

.tablet({
	&:before{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		content: '';
		background: rgba(0,0,0,.5);
	}

	.mobile-only {
		display: none;
	}
	.inner{
		float: right;
		width: 600px;
	}

	li{
		padding: 6px 0;
	}

	#close{
		top: 55px;
		right: 90px;
	}
});
}