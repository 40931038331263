.block.header{
	position: relative;
	background: @page-bg;
	padding: 0;
	height: fit-content;
	min-height: 100vh;

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0,0,0,.9);
		pointer-events: none;
		opacity: 0.5;
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}

	}

	.scroll-down {
		a {
			height: fit-content;
			width: fit-content;
			margin: auto;
			background: none !important;
			border-radius: 0;
			padding: 0;
		}
		img {
			object-fit: contain;
			height: 125px;
			margin: 0 auto;
			display: flex
		}
	}

	&.slim{
		min-height: 55vh;
		height: fit-content;
		h1,h2,h3,h4,h5,h6{
			font-size: 5rem;
		}
	}

	.inner{
		position: static;
		height: 100%;
	}

	.bg-box{
		position: absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;

		&:after{
			position: absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			content: '';
			background: rgba(0,0,0,.5);
		}

		img{
			position: relative;
			display: block;
			height: 100%;
			min-width: 100%;
			max-width: 1000%;
			width: auto;
		}
	}

	article{
		position: relative;
		padding: 200px 20px 0 20px;
		color: @text-alt;
		font-size: 1.8rem;
		text-align: center;

		h1,h2,h3,h4,h5,h6{
			color: @text-alt;
			font-size: 1.8rem;
			text-align: center;
			margin: auto;
			max-width: 80%;
			padding: 0 0 20px;
			font-family: @main;
		}
	}

	&.slim {
		article {
			h1,h2,h3,h4,h5,h6{
				font-family: @title;
			}
		}
	}

	.logo{
		position: relative;

		img{
			display: block;
			margin: auto;
			width: 180px;
		}
	}

	a{
		.button;
		margin: auto;
	}

	&::after{
		position: absolute;
		bottom: -1px;
		left:0;
		right:0;
		content:"";
		background: url("/assets/img/white-ripped.png") bottom center repeat;
		// background-size: cover;
    width: 100%;
    height: 60px;
	}

.tablet({

	min-height: 100vh;

});


}

#rs-builder {
	.rs-rs-bar {
		z-index: 1;
	}
	.block.header {
		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			height: 200px;
			width: 200px;
			background: rgba(0,0,0,.9);
			pointer-events: all;
			z-index: 1;
		}
	}
}